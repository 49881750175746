import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/games-icon/home-icon.png";
import CasinoIcon from "../assets/images/games-icon/casino-icon.png";
import InplayIcon from "../assets/images/games-icon/inplay-icon.png";
import aviator from "../assets/images/games-icon/aviator.png";

import CricketIcon from "../assets/images/games-icon/cricket-icon.png";
import TennisIcon from "../assets/images/games-icon/tennis-icon.png";
import SoccerIcon from "../assets/images/games-icon/soccer-icon.png";
import TableTennisIcon from "../assets/images/games-icon/table-tennis-icon.png";
import BasketballIcon from "../assets/images/games-icon/basketball-icon.png";
import IceHockeyIcon from "../assets/images/games-icon/icehockey-icon.png";
import BadmintonIcon from "../assets/images/games-icon/badminton-icon.png";
import ArcheryIcon from "../assets/images/games-icon/archery-icon.png";
import MotorSportIcon from "../assets/images/games-icon/motor-sport-icon.png";
import horseracingIcon from "../assets/images/games-icon/horseracing-icon.png";
import GreyhoundIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";
import Apk from "../assets/images/games-icon/Apk.png";

import WalletIcon from "../assets/images/WalletIcon.png";
import UserIcon from "../assets/images/user-icon.svg";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import LogoutIconImg from "../assets/images/logout.png";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";
import { isMobile } from "react-device-detect";

const MobLeftbar = ({ setShowAuthModals }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const { user, balance, wallet } = useSelector((state) => state.auth);

  const handleNavigation = (url, navigationState = {}) => {
    if (isAuth) {
      navigate(url, {
        state: navigationState,
      });
    } else {
      if (isMobile) {
        setShowAuthModals(true);
      } else {
        navigate(url);
      }
    }
  };

  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec">
        {isAuth && (
          <>
            <div className="d-flex profilemobsection">
              <div className="profile-img">
                <img src={UserIcon} alt="User Icon" />
              </div>
              <div className="user-info">
                <Link className="user_name" to="/profile">
                  {user?.mstruserid}
                </Link>
                <p>{user?.mobileno}</p>
              </div>
            </div>
            <div className="container upperbalnace">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-4">
                    <img src={WalletIcon} alt="wallet" />
                    Balance
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-4">
                    {" "}
                    INR {wallet?.balance}
                  </div>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    handleNavigation("/withdraw");
                  }}
                >
                  <Button variant="" className="btn_withdraw w-100">
                    Withdraw
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    handleNavigation("/gatewaylist");
                  }}
                >
                  <Button variant="primary" className="btn_primary w-100">
                    Deposit
                  </Button>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    handleNavigation("/bonus");
                  }}
                >
                  <Button variant="" className="btn_withdraw mt-2 w-100">
                    Bonus
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    handleNavigation("/mybets");
                  }}
                >
                  <Button variant="" className="btn_withdraw  mt-2 w-100">
                    My Bets
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        <ul>
          <li className="games_link">
            <h6>Quick Links</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/sports");
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/casino/spribe/aviator");
                  }}
                >
                  <img src={aviator} alt="Inplay Icon" />
                  <span>Aviator</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/spmsports/cricket", {
                      selectedTab: "1",
                    });
                  }}
                >
                  <img src={InplayIcon} alt="Inplay Icon" />
                  <span>Exchange</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/betby", {
                      selectedTab: "1",
                    });
                  }}
                >
                  <img src={InplayIcon} alt="Inplay Icon" />
                  <span>Sports</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/casino/worldcasino");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>World Casino</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link">
            <h6>Sports</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Cricket", {
                      selectedTab: "2",
                    });
                  }}
                >
                  <img src={CricketIcon} alt="Cricket Icon" />
                  <span>Cricket</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Tennis", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={TennisIcon} alt=" Icon" />
                  <span>Tennis</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Soccer", {
                      selectedTab: "3",
                    });
                  }}
                >
                  <img src={SoccerIcon} alt="Soccer Icon" />
                  <span>Soccer</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Table Tennis", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={TableTennisIcon} alt="Table Tennis Icon" />
                  <span>Table Tennis</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Basketball", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={BasketballIcon} alt="Basketball Icon" />
                  <span>Basketball</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Ice Hockey", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={IceHockeyIcon} alt="Ice Hockey Icon" />
                  <span>Ice Hockey</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Badminton", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={BadmintonIcon} alt="Badminton Icon" />
                  <span>Badminton</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Archery", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={ArcheryIcon} alt="Archery Icon" />
                  <span>Archery</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Motor Sport", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={MotorSportIcon} alt="Motor Sport Icon" />
                  <span>Motor Sport</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Horse Racing", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={horseracingIcon} alt="horse racing Icon" />
                  <span>Horse Racing</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/exchange/Greyhound Racing", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={GreyhoundIcon} alt="Greyhound Racing Icon" />
                  <span>Greyhound Racing</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    handleNavigation("/sportsbook/Politics", {
                      selectedTab: "4",
                    });
                  }}
                >
                  <img src={PoliticsIcon} alt="Politics Icon" />
                  <span>Politics</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link">
            <h6>My Account</h6>
            <ul>
              <li>
                <div>
                  <a
                    onClick={() => {
                      handleNavigation("/mybets", {
                        selectedTab: "2",
                      });
                    }}
                  >
                    <img src={BetIcon} alt="my bet" />
                    <span>My Bets</span>
                  </a>
                </div>
              </li>
              <li>
                <div>
                  <a
                    className="img_size"
                    onClick={() => {
                      handleNavigation("/bonus", {
                        selectedTab: "2",
                      });
                    }}
                  >
                    <img src={BonusImg} />
                    <span className="bonus-space">Bonus</span>
                  </a>
                </div>
              </li>
              <li>
                <div>
                  <a
                    className="img_size"
                    onClick={() => {
                      handleNavigation("/referral", {
                        selectedTab: "2",
                      });
                    }}
                  >
                    <img src={refferal} />
                    <span className="bonus-space">Referral</span>
                  </a>
                </div>
              </li>
              <li>
                <div>
                  <a
                    onClick={() => {
                      handleNavigation("/cashier", {
                        selectedTab: "2",
                      });
                    }}
                  >
                    <img src={AcStatement} alt="account statement" />
                    <span>Account Statement</span>
                  </a>
                </div>
              </li>
              {isAuth && (
                <li onClick={() => dispatch(logoutUser())}>
                  <div>
                    <a href="/">
                      {/* <LogoutIcon /> */}
                      <img src={LogoutIconImg} />
                      <span>Sign Out</span>
                    </a>
                  </div>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobLeftbar;
